/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { createTask as n } from "./asyncUtils.js";
import e from "./Collection.js";
import { isEventedOrEventTarget as t, on as o } from "./events.js";
import { handlesGroup as r, destroyHandle as s, makeHandle as i } from "./handleUtils.js";
import { removeMaybe as c } from "./maybe.js";
import { isAborted as u, createAbortError as l, onAbort as a, ignoreAbortErrors as f } from "./promiseUtils.js";
import { watchTracked as m } from "./accessorSupport/watch.js";
export { autorun } from "./accessorSupport/trackingUtils.js";
function d(n, e, t = {}) {
  return y(n, e, t, L);
}
function p(n, e, t = {}) {
  return y(n, e, t, M);
}
function y(n, e, t = {}, o) {
  let r = null;
  const s = t.once ? (n, t) => {
    o(n) && (c(r), e(n, t));
  } : (n, t) => {
    o(n) && e(n, t);
  };
  if (r = m(n, s, t.sync, t.equals), t.initial) {
    const e = n();
    s(e, e);
  }
  return r;
}
function v(n, e, r, s = {}) {
  let u = null,
    l = null,
    a = null;
  function f() {
    u && l && (l.remove(), s.onListenerRemove?.(u), u = null, l = null);
  }
  function m(n) {
    s.once && s.once && c(a), r(n);
  }
  const p = d(n, (n, r) => {
    f(), t(n) && (u = n, l = o(n, e, m), s.onListenerAdd?.(n));
  }, {
    sync: s.sync,
    initial: !0
  });
  return a = i(() => {
    p.remove(), f();
  }), a;
}
function j(n, e) {
  return h(n, null, e);
}
function w(n, e) {
  return h(n, M, e);
}
function h(n, e, t) {
  if (u(t)) return Promise.reject(l());
  const o = n();
  if (e?.(o)) return Promise.resolve(o);
  let s = null;
  function i() {
    s = c(s);
  }
  return new Promise((o, c) => {
    s = r([a(t, () => {
      i(), c(l());
    }), y(n, n => {
      i(), o(n);
    }, {
      sync: !1,
      once: !0
    }, e ?? L)]);
  });
}
function L(n) {
  return !0;
}
function M(n) {
  return !!n;
}
function U(n, e, t = {}) {
  let o = !1;
  const r = d(n, (n, t) => {
    o || e(n, t);
  }, t);
  return {
    remove() {
      r.remove();
    },
    pause() {
      o = !0;
    },
    resume() {
      o = !1;
    }
  };
}
function g(n, t) {
  const o = new e(),
    r = v(n, "after-splice", ({
      added: n,
      start: e,
      deleteCount: r
    }) => {
      const s = o.splice(e, r);
      for (const t of s) t.remove();
      if (n?.length) {
        const r = n.map(t);
        o.addMany(r, e);
      }
    }, {
      sync: !0,
      onListenerRemove: () => o.drain(n => n.remove()),
      onListenerAdd: n => o.addMany(n.items.map(t))
    });
  return o.addHandles(r), o;
}
function x(t, o) {
  const r = new e(),
    i = g(t, e => n(async n => {
      const t = await o(e, n);
      if (u(n)) throw t.remove(), l();
      return t;
    })),
    c = () => null,
    a = async n => {
      const e = await n.promise,
        t = i.indexOf(n);
      t < 0 || r.splice(t, 1, e);
    };
  r.addMany(i.items.map(c));
  for (const n of i) f(a(n));
  const m = i.on("after-splice", ({
    added: n,
    start: e,
    deleteCount: t
  }) => {
    const o = r.splice(e, t);
    for (const r of o) r?.remove();
    if (n?.length) {
      r.addMany(n.map(c), e);
      for (const e of n) f(a(e));
    }
  });
  return r.addHandles([s(i), m]), r;
}
const C = {
    sync: !0
  },
  P = {
    initial: !0
  },
  A = {
    sync: !0,
    initial: !0
  };
export { P as initial, g as mapCollection, x as mapCollectionAsync, v as on, j as once, U as pausable, C as sync, A as syncAndInitial, d as watch, p as when, w as whenOnce };